"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_theming_1 = require("@storybook/react-native-theming");
const Container = react_native_theming_1.styled.View(() => ({
    alignItems: 'flex-start',
}));
const BooleanType = ({ arg, onChange }) => ((0, jsx_runtime_1.jsx)(Container, { children: (0, jsx_runtime_1.jsx)(react_native_1.Switch, { testID: arg.name, onValueChange: () => onChange(!arg.value), value: arg.value }) }));
BooleanType.serialize = (value) => (value ? String(value) : null);
BooleanType.deserialize = (value) => value === 'true';
exports.default = BooleanType;
