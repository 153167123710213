"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_theming_1 = require("@storybook/react-native-theming");
const common_1 = require("./common");
const useResyncValue_1 = require("./useResyncValue");
const Input = react_native_theming_1.styled.TextInput(({ theme }) => ({
    ...(0, common_1.inputStyle)(theme),
}));
const TextType = ({ arg, onChange, isPristine }) => {
    const { setCurrentValue, key } = (0, useResyncValue_1.useResyncValue)(arg.value, isPristine);
    return ((0, jsx_runtime_1.jsx)(Input, { testID: arg.name, defaultValue: arg.value, onChangeText: (text) => {
            onChange(text);
            setCurrentValue(text);
        }, autoCapitalize: "none", underlineColorAndroid: "transparent" }, key));
};
TextType.defaultProps = {
    arg: {},
    onChange: (value) => value,
};
TextType.propTypes = {};
TextType.serialize = (value) => value;
TextType.deserialize = (value) => value;
exports.default = TextType;
