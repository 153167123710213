"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResyncValue = void 0;
const react_1 = require("react");
// syncs up the value of a control with the value of a story arg when resetting
// this is used for controls that don't use a controlled input like the slider
function useResyncValue(value, isPristine, resyncCallback) {
    const [key, setKey] = (0, react_1.useState)(0);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(value);
    (0, react_1.useEffect)(() => {
        if (isPristine && value !== currentValue) {
            setKey((cur) => cur + 1);
            resyncCallback?.(value);
        }
    }, [value, currentValue, isPristine, resyncCallback]);
    return { key, setCurrentValue };
}
exports.useResyncValue = useResyncValue;
