"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_theming_1 = require("@storybook/react-native-theming");
const color_picker_1 = require("../components/color-picker");
const TouchableContainer = react_native_theming_1.styled.View(({ theme }) => ({
    width: theme.inputs.swatch.height,
    height: theme.inputs.swatch.height,
    borderWidth: theme.inputs.swatch.borderWidth,
    borderColor: theme.inputs.swatch.borderColor,
    borderRadius: theme.inputs.swatch.outerBorderRadius,
    paddingVertical: theme.inputs.swatch.paddingVertical,
    paddingHorizontal: theme.inputs.swatch.paddingHorizontal,
    backgroundColor: theme.inputs.swatch.backgroundColor,
}));
const Touchable = react_native_theming_1.styled.TouchableOpacity(({ theme, color }) => ({
    width: '100%',
    height: '100%',
    borderRadius: theme.inputs.swatch.innerBorderRadius,
    backgroundColor: color,
}));
const WebInput = (0, react_native_theming_1.styled)('input')(({ theme }) => ({
    width: theme.inputs.swatch.height,
    height: theme.inputs.swatch.height,
    borderWidth: theme.inputs.swatch.borderWidth,
    borderColor: theme.inputs.swatch.borderColor,
    borderRadius: theme.inputs.swatch.outerBorderRadius,
    paddingVertical: theme.inputs.swatch.paddingVertical,
    paddingHorizontal: theme.inputs.swatch.paddingHorizontal,
    backgroundColor: theme.inputs.swatch.backgroundColor,
}));
const ButtonTouchable = react_native_theming_1.styled.TouchableOpacity(({ theme, primary }) => {
    const buttonTheme = primary ? theme.button.primary : theme.button.secondary;
    return {
        backgroundColor: buttonTheme.backgroundColor,
        borderRadius: buttonTheme.borderRadius,
        borderWidth: buttonTheme.borderWidth,
        borderColor: buttonTheme.borderColor,
        paddingVertical: theme.button.paddingVertical,
        paddingHorizontal: theme.button.paddingHorizontal,
        justifyContent: 'center',
        alignItems: 'center',
    };
});
const ButtonText = react_native_theming_1.styled.Text(({ theme, primary }) => {
    const buttonTheme = primary ? theme.button.primary : theme.button.secondary;
    return {
        color: buttonTheme.textColor,
        fontSize: theme.button.fontSize,
        fontWeight: theme.button.fontWeight,
    };
});
const ColorType = ({ arg, onChange = (value) => value }) => {
    const [displayColorPicker, setDisplayColorPicker] = (0, react_1.useState)(false);
    const [currentColor, setCurrentColor] = (0, react_1.useState)(null);
    const openColorPicker = () => {
        setDisplayColorPicker(true);
    };
    const closeColorPicker = () => {
        setDisplayColorPicker(false);
    };
    const onChangeColor = (color) => {
        onChange((0, color_picker_1.fromHsv)(color));
    };
    if (react_native_1.Platform.OS === 'web') {
        return ((0, jsx_runtime_1.jsx)(WebInput, { type: "color", value: arg.value, onChange: (event) => onChange(event.target.value) }));
    }
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { children: [(0, jsx_runtime_1.jsx)(TouchableContainer, { children: (0, jsx_runtime_1.jsx)(Touchable, { color: arg.value, onPress: openColorPicker }) }), (0, jsx_runtime_1.jsxs)(react_native_1.Modal, { supportedOrientations: ['portrait', 'landscape'], transparent: true, visible: displayColorPicker, onRequestClose: closeColorPicker, children: [(0, jsx_runtime_1.jsx)(react_native_1.TouchableWithoutFeedback, { onPress: closeColorPicker, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.modalOverlay }) }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.centerContainer, children: (0, jsx_runtime_1.jsxs)(InnerContainer, { pointerEvents: "box-none", children: [(0, jsx_runtime_1.jsx)(color_picker_1.ColorPicker, { onColorSelected: onChangeColor, onColorChange: (color) => setCurrentColor(color), defaultColor: arg.value, style: styles.picker }), (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.actionContainer, children: [(0, jsx_runtime_1.jsx)(ButtonTouchable, { onPress: closeColorPicker, children: (0, jsx_runtime_1.jsx)(ButtonText, { children: "CANCEL" }) }), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { width: 12 } }), (0, jsx_runtime_1.jsx)(ButtonTouchable, { primary: true, onPress: () => {
                                                onChangeColor(currentColor);
                                                closeColorPicker();
                                            }, children: (0, jsx_runtime_1.jsx)(ButtonText, { primary: true, children: "SELECT" }) })] })] }) })] })] }));
};
const InnerContainer = react_native_theming_1.styled.View(({ theme }) => ({
    backgroundColor: theme.panel.backgroundColor,
    borderWidth: theme.panel.borderWidth,
    borderColor: theme.panel.borderColor,
    borderRadius: theme.tokens.borderRadius.large,
    margin: 24,
    padding: theme.tokens.spacing3,
    maxWidth: 350,
    height: 400,
    maxHeight: react_native_1.Dimensions.get('screen').height - 24 * 2,
    ...theme.tokens.elevation.floating,
}));
const styles = react_native_1.StyleSheet.create({
    actionContainer: {
        paddingTop: 8,
        alignItems: 'flex-end',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
    },
    picker: { flex: 1 },
    centerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalOverlay: {
        ...react_native_1.StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
});
ColorType.serialize = (value) => value;
ColorType.deserialize = (value) => value;
exports.default = ColorType;
