"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_theming_1 = require("@storybook/react-native-theming");
const useResyncValue_1 = require("./useResyncValue");
const common_1 = require("./common");
const Input = react_native_theming_1.styled.TextInput(({ theme }) => ({
    ...(0, common_1.inputStyle)(theme, false),
    minHeight: 60,
}));
const ObjectType = ({ arg, onChange, isPristine }) => {
    const getJsonString = (0, react_1.useCallback)(() => {
        try {
            return JSON.stringify(arg.value, null, 2);
        }
        catch (error) {
            return '';
        }
    }, [arg.value]);
    const [failed, setFailed] = (0, react_1.useState)(false);
    const { key, setCurrentValue } = (0, useResyncValue_1.useResyncValue)(arg.value, isPristine);
    const handleChange = (value) => {
        const withReplacedQuotes = value
            .replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201C\u201D]/g, '"');
        try {
            const json = JSON.parse(withReplacedQuotes.trim());
            onChange(json);
            setCurrentValue(json);
            setFailed(false);
        }
        catch (err) {
            setFailed(true);
        }
    };
    const extraStyle = {};
    if (failed) {
        extraStyle.borderWidth = 1;
        extraStyle.borderColor = '#fadddd';
        extraStyle.backgroundColor = '#fff5f5';
    }
    return ((0, jsx_runtime_1.jsx)(Input, { testID: arg.name, style: extraStyle, defaultValue: getJsonString(), onChangeText: handleChange, multiline: true, autoCapitalize: "none", underlineColorAndroid: "transparent" }, key));
};
ObjectType.serialize = (object) => JSON.stringify(object);
ObjectType.deserialize = (value) => (value ? JSON.parse(value) : {});
exports.default = ObjectType;
