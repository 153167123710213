"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_theming_1 = require("@storybook/react-native-theming");
const react_native_1 = require("react-native");
const Paragraph = react_native_theming_1.styled.Text(({ theme }) => ({
    marginBottom: theme.tokens.spacing3,
    color: theme.text.primaryColor,
}));
const LinkText = react_native_theming_1.styled.Text(({ theme }) => ({
    color: theme.text.linkColor,
}));
const NoControlsWarning = () => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Paragraph, { children: "This story is not configured to handle controls." }), (0, jsx_runtime_1.jsxs)(Paragraph, { children: [(0, jsx_runtime_1.jsx)(LinkText, { onPress: () => react_native_1.Linking.openURL('https://storybook.js.org/docs/react/essentials/controls'), children: "Learn how to add controls" }), ' ', "and see", ' ', (0, jsx_runtime_1.jsx)(LinkText, { onPress: () => react_native_1.Linking.openURL('https://github.com/storybookjs/react-native/tree/next-6.0/examples/expo-example/components/ControlExamples'), children: "examples in the Storybook React Native repository." })] })] }));
};
exports.default = NoControlsWarning;
