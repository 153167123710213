"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_theming_1 = require("@storybook/react-native-theming");
const common_1 = require("./common");
const useResyncValue_1 = require("./useResyncValue");
const Input = react_native_theming_1.styled.TextInput(({ theme }) => ({
    ...(0, common_1.inputStyle)(theme),
}));
function formatArray(value, separator) {
    if (value === '') {
        return [];
    }
    return value.split(separator);
}
const ArrayType = ({ arg: { name, value, separator = ',' }, onChange = () => null, isPristine, }) => {
    const { setCurrentValue, key } = (0, useResyncValue_1.useResyncValue)(value, isPristine);
    return ((0, jsx_runtime_1.jsx)(Input, { testID: name, underlineColorAndroid: "transparent", autoCapitalize: "none", defaultValue: value?.join(separator), onChangeText: (text) => {
            const formatted = formatArray(text, separator);
            onChange(formatted);
            setCurrentValue(formatted);
        } }, key));
};
ArrayType.serialize = (value) => value;
ArrayType.deserialize = (value) => {
    if (Array.isArray(value)) {
        return value;
    }
    return Object.keys(value)
        .sort()
        .reduce((array, key) => [...array, value[key]], []);
};
exports.default = ArrayType;
