"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddonPanel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const AddonPanel = ({ active, children }) => {
    if (!active) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
};
exports.AddonPanel = AddonPanel;
exports.AddonPanel.displayName = 'AddonPanel';
