"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_theming_1 = require("@storybook/react-native-theming");
const RadioContainer = react_native_theming_1.styled.View(({ isInline }) => ({
    flexDirection: isInline ? 'row' : 'column',
    alignItems: isInline ? 'center' : 'flex-start',
    flexWrap: 'wrap',
}));
const RadioTouchable = react_native_theming_1.styled.TouchableOpacity(({ theme }) => ({
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: theme.inputs.radio.itemSpacing,
}));
const RadioCircle = react_native_theming_1.styled.View(({ theme }) => ({
    width: theme.inputs.radio.height,
    height: theme.inputs.radio.height,
    borderWidth: theme.inputs.radio.borderWidth,
    borderColor: theme.inputs.radio.borderColor,
    borderRadius: theme.tokens.borderRadius.round,
    backgroundColor: theme.inputs.radio.backgroundColor,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.inputs.radio.paddingVertical,
    paddingHorizontal: theme.inputs.radio.paddingHorizontal,
}));
const RadioInnerCircle = react_native_theming_1.styled.View(({ theme, selected }) => ({
    height: '100%',
    width: '100%',
    borderRadius: theme.tokens.borderRadius.round,
    backgroundColor: selected ? theme.inputs.radio.activeBackgroundColor : 'transparent',
}));
const RadioLabel = react_native_theming_1.styled.Text(({ theme }) => ({
    fontSize: theme.inputs.radio.fontSize,
    paddingStart: theme.inputs.radio.labelSpacing,
    color: theme.inputs.labelTextColor,
}));
const RadioSelect = ({ data = [], value = '', onChange, isInline }) => {
    return ((0, jsx_runtime_1.jsx)(RadioContainer, { isInline: isInline, children: data.map((item) => ((0, jsx_runtime_1.jsxs)(RadioTouchable, { activeOpacity: 0.7, onPress: () => {
                onChange(item);
            }, children: [(0, jsx_runtime_1.jsx)(RadioCircle, { children: (0, jsx_runtime_1.jsx)(RadioInnerCircle, { selected: value === item.key }) }), (0, jsx_runtime_1.jsx)(RadioLabel, { children: item.label })] }, item.label))) }));
};
exports.default = RadioSelect;
