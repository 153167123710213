"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputStyle = void 0;
const react_native_1 = require("react-native");
function inputStyle(theme, isTextInput = true) {
    return {
        backgroundColor: theme.inputs.text.backgroundColor,
        borderWidth: theme.inputs.text.borderWidth,
        borderColor: theme.inputs.text.borderColor,
        borderRadius: theme.inputs.text.borderRadius,
        fontSize: theme.inputs.text.fontSize,
        color: theme.inputs.text.textColor,
        paddingHorizontal: theme.inputs.text.paddingHorizontal,
        ...react_native_1.Platform.select({
            android: {
                // Android seems to have builtin vertical padding to `TextInput`,
                // but not for multiline inputs.
                paddingVertical: isTextInput ? 0 : undefined,
            },
            web: {
                // The web (that isn't RNW) doesn't understand `paddingHorizontal` etc.
                paddingLeft: theme.inputs.text.paddingHorizontal,
                paddingRight: theme.inputs.text.paddingHorizontal,
                paddingTop: theme.inputs.text.paddingVertical,
                paddingBottom: theme.inputs.text.paddingVertical,
                borderStyle: 'solid',
            },
            default: {
                paddingVertical: theme.inputs.text.paddingVertical,
            },
        }),
        margin: 0,
    };
}
exports.inputStyle = inputStyle;
