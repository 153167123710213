"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const theme = {
    OBJECT_NAME_COLOR: 'rgb(136, 19, 145)',
    OBJECT_VALUE_NULL_COLOR: 'rgb(128, 128, 128)',
    OBJECT_VALUE_UNDEFINED_COLOR: 'rgb(128, 128, 128)',
    OBJECT_VALUE_REGEXP_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_STRING_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_SYMBOL_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_NUMBER_COLOR: 'rgb(28, 0, 207)',
    OBJECT_VALUE_BOOLEAN_COLOR: 'rgb(28, 0, 207)',
    OBJECT_VALUE_FUNCTION_PREFIX_COLOR: 'rgb(13, 34, 170)',
    ARROW_COLOR: '#859499',
};
const Inspect = ({ name, value }) => {
    const [expanded, setExpanded] = (0, react_1.useState)(false);
    const canExpand = name &&
        ((Array.isArray(value) && value.length) ||
            (value && typeof value === 'object' && Object.keys(value).length));
    const toggleExpanded = react_1.default.useCallback(() => {
        if (canExpand) {
            setExpanded((currentValue) => !currentValue);
        }
    }, [canExpand]);
    const toggle = ((0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: canExpand ? theme.ARROW_COLOR : 'transparent', paddingRight: 8 }, children: expanded ? '▼' : '▶' }));
    const nameComponent = name ? ((0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_NAME_COLOR }, children: name })) : null;
    if (Array.isArray(value)) {
        if (name) {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(react_native_1.TouchableOpacity, { onPress: toggleExpanded, style: styles.row, children: [toggle, nameComponent, (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: `: ${value.length === 0 ? '[]' : expanded ? '[' : '[...]'}` })] }), expanded ? ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.expanded, children: [value.map((v, i) => ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.expanded, children: (0, jsx_runtime_1.jsx)(Inspect, { value: v }) }, i))), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.spacingLeft, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: "]" }) })] })) : null] }));
        }
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_native_1.Text, { children: "[" }), value.map((v, i) => ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.spacingLeft, children: (0, jsx_runtime_1.jsx)(Inspect, { value: v }) }, i))), (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: "]" })] }));
    }
    if (value && typeof value === 'object' && !(value instanceof RegExp)) {
        if (name) {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(react_native_1.TouchableOpacity, { style: styles.row, onPress: toggleExpanded, children: [toggle, nameComponent, (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: `: ${Object.keys(value).length === 0 ? '{}' : expanded ? '{' : '{...}'}` })] }), expanded ? ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.expanded, children: [Object.entries(value).map(([key, v]) => ((0, jsx_runtime_1.jsx)(react_native_1.View, { children: (0, jsx_runtime_1.jsx)(Inspect, { name: key, value: v }) }, key))), (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.spacingLeft, children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: '}' }) })] })) : null] }));
        }
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_native_1.Text, { children: '{' }), Object.entries(value).map(([key, v]) => ((0, jsx_runtime_1.jsx)(react_native_1.View, { children: (0, jsx_runtime_1.jsx)(Inspect, { name: key, value: v }) }, key))), (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: '}' })] }));
    }
    if (name) {
        return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.row, children: [toggle, nameComponent, (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: ": " }), (0, jsx_runtime_1.jsx)(Value, { value: value })] }));
    }
    return (0, jsx_runtime_1.jsx)(Value, { value: value });
};
function Value({ value }) {
    if (value === null) {
        return (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_NULL_COLOR }, children: "null" });
    }
    if (value === undefined) {
        return (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_UNDEFINED_COLOR }, children: "undefined" });
    }
    if (value instanceof RegExp) {
        return ((0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_REGEXP_COLOR }, children: `/${value.source}/${value.flags}` }));
    }
    switch (typeof value) {
        case 'string':
            return ((0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_STRING_COLOR }, children: JSON.stringify(value) }));
        case 'number':
            return ((0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_NUMBER_COLOR }, children: JSON.stringify(value) }));
        case 'boolean':
            return ((0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_BOOLEAN_COLOR }, children: JSON.stringify(value) }));
        case 'function':
            return (0, jsx_runtime_1.jsx)(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_FUNCTION_PREFIX_COLOR }, children: "[Function]" });
        default:
            return (0, jsx_runtime_1.jsx)(react_native_1.Text, { children: JSON.stringify(value) });
    }
}
exports.default = Inspect;
const styles = react_native_1.StyleSheet.create({
    spacingLeft: { marginLeft: 20 },
    expanded: { marginLeft: 20 },
    row: { paddingBottom: 8, flexDirection: 'row', alignItems: 'center' },
});
